// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-template-js": () => import("./../src/templates/aboutTemplate.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-gin-template-js": () => import("./../src/templates/ginTemplate.js" /* webpackChunkName: "component---src-templates-gin-template-js" */),
  "component---src-templates-news-template-js": () => import("./../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-whisky-template-js": () => import("./../src/templates/whiskyTemplate.js" /* webpackChunkName: "component---src-templates-whisky-template-js" */)
}

